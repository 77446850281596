import React, { useEffect, useState } from 'react';
import "../global.css";
import "../style/services.css";
import "../style/provider.css";
import Header from "../components/header";
import Testimonial from "../components/testimonial";
import Latest_Blog from "../components/latest-blog";
import Footer from "../components/footer";
import CTA from "../components/cta";
import SEOWrapper from '../components/SEOWrapper';
import Our_Provider from '../components/our-provider';
import { fetchProviders } from '../utils/api'; // Ensure this path matches where your fetchProviders function is located

import phone from "../assets/phone-icon.png";
import enevelop from "../assets/envelope-icon.png";
import timer from "../assets/timer-icon.png";

const Provider = () => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadProviders = async () => {
      try {
        const data = await fetchProviders();
        setProviders(data);
      } catch (err) {
        setError('Failed to fetch providers.');
      } finally {
        setLoading(false);
      }
    };

    loadProviders();
  }, []);

  const pageName = new URL(import.meta.url).pathname.split('/').pop().replace('.jsx', '');

  return (
    <SEOWrapper pageName={pageName}>
      <div>
        <Header />
        <section className='provider-breadcrumb breadcrumb-img'>
          <h1 className="text-center heading">{pageName}</h1>
        </section>
        <section className="hero-banner-contactinfo w-100">
          <div className="container">
            <div className="hero-banner-contactinfo-content">
              <div className="row">
                <div className="col-lg-4">
                  <div className="contactinfo-box">
                    <img className="img-fluid" loading="lazy" alt="Phone Icon" src={phone}/>
                    <h3>4098344100</h3>
                    <p>Keep in touch</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contactinfo-box">
                    <img className="img-fluid" loading="lazy" alt="Envelope Icon" src={enevelop}/>
                    <h3>admin@caraccidnetcares.com</h3>
                    <p>Keep in touch</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contactinfo-box">
                    <img className="img-fluid" loading="lazy" alt="Timer Icon" src={timer}/>
                    <h3>08am - 05pm</h3>
                    <p>Monday to Friday</p>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </section>
        {loading && <p>Loading providers...</p>}
        {error && <p>{error}</p>}
        {!loading && !error && <Our_Provider providers={providers} />}
        <Testimonial />
        <Latest_Blog />
        <CTA />
        <Footer />
      </div>
    </SEOWrapper>
  );
};

export default Provider;
