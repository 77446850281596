import React, { useEffect, useState, useMemo } from "react";
import "./our-provider.css";
import "../global.css";
import { fetchProviders } from '../utils/api'; // Assuming fetchProviders is imported from utils/api

const Our_Provider = () => {
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filter states
  const [searchTerm, setSearchTerm] = useState("");
  const [specialityFilter, setSpecialityFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [areaFilter, setAreaFilter] = useState("");

  useEffect(() => {
    const loadProviders = async () => {
      try {
        const data = await fetchProviders();
        setProviders(data);
      } catch (err) {
        setError("Failed to load providers. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    loadProviders();
  }, []);

  const filteredProviders = useMemo(() => {
    return providers.filter(provider => {
      return (
        (searchTerm === "" || provider.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (specialityFilter === "" || provider.specialities.includes(specialityFilter)) &&
        (locationFilter === "" || provider.city === locationFilter) &&
        (areaFilter === "" || provider.area === areaFilter)
      );
    });
  }, [searchTerm, specialityFilter, locationFilter, areaFilter, providers]);

  if (loading) return <div>Loading providers...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <section className="our-provider">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="filter-controls">
              <div className="dropdown-filter">
                <select value={specialityFilter} onChange={(e) => setSpecialityFilter(e.target.value)} className="filter-dropdown" >
                  <option value="">All Specialities</option>
                  {Array.from(new Set(providers.flatMap(provider => provider.specialities))).map(speciality => (
                    <option key={speciality} value={speciality}>{speciality}</option>
                  ))}
                </select>
                <select value={locationFilter} onChange={(e) => setLocationFilter(e.target.value)} className="filter-dropdown" >
                  <option value="">All Locations</option>
                  {Array.from(new Set(providers.map(provider => provider.city))).map(location => (
                    <option key={location} value={location}>{location}</option>
                  ))}
                </select>
                <select value={areaFilter} onChange={(e) => setAreaFilter(e.target.value)} className="filter-dropdown" >
                  <option value="">All Areas</option>
                  {Array.from(new Set(providers.map(provider => provider.area))).map(area => (
                    <option key={area} value={area}>{area}</option>
                  ))}
                </select>
                <button className="clear-btn blue-btn">Clear</button>
              </div>
              <div className="search-bar-field">
                <input type="text" placeholder="Search by provider name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="search-bar" />
              </div>
            </div>            
          </div>
          {filteredProviders.map(provider => (
            <div className="col-lg-4 col-md-6 mb-4" key={provider._id}>
              <div className="provider-card">
                <h2 className="provider-name text-center">{provider.name}</h2>
                <hr/>
                <p className="provider-specialities"><span>Specialities:</span> <span className="a-badge">{provider.specialities.join(', ')}</span></p>
                <p className="provider-location"><span>Location:</span> <span className="a-badge">{provider.city}</span> </p>
                <p className="provider-area"><span>Area:</span> <span className="a-badge">{provider.area}</span> </p>
                <p className="provider-address"><span>Address:</span> <span className="a-badge">{provider.address}</span> </p>
                <a href={provider.location} target="_blank" rel="noopener noreferrer" className="blue-btn" >       
                  View on Map
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Our_Provider;
