import React from 'react';
import "../global.css";
import "../style/blog.css";
import SEOWrapper from '../components/SEOWrapper';
import Header from '../components/header';
import Testimonial from '../components/testimonial';
import Latest_Blog2 from '../components/latest-blog2';
import CTA from '../components/cta';
import Footer from '../components/footer';

import phone from "../assets/phone-icon.png";
import enevelop from "../assets/envelope-icon.png";
import timer from "../assets/timer-icon.png";

const Blog = () => {
  const pageName = new URL(import.meta.url).pathname.split('/').pop().replace('.jsx', '');

  return (
    <>
    <SEOWrapper pageName={pageName} />
    <Header />
    <section className='blog-breadcrumb breadcrumb-img'>
      <h1 className='text-center heading'>{pageName}</h1>      
    </section>
    <section className="hero-banner-contactinfo w-100">
      <div className="container">
        <div className="hero-banner-contactinfo-content">
          <div className="row">
            <div className="col-lg-4">
              <div className="contactinfo-box">
                <img className="img-fluid" loading="lazy" alt="" src={phone} />
                <h3>4098344100</h3>
                <p>Keep on touch</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contactinfo-box">
                <img className="img-fluid" loading="lazy" alt="" src={enevelop}/>
                <h3>admin@caraccidnetcares.com</h3>
                <p>Keep on touch</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contactinfo-box">
                <img className="img-fluid" loading="lazy" alt="" src= {timer}/>
                <h3>08am - 05pm</h3>
                <p>Monday to Friday</p>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </section>
    <Latest_Blog2 />
    <Testimonial />
    <CTA />
    <Footer />
    </>    

  )
};
export default Blog;

