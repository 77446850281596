import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./latest-blog.css";
import "../global.css";
import { fetchAllPosts } from '../utils/api'; // Updated import
import { getImageUrl } from '../utils/imageUtils';

const Latest_Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const data = await fetchAllPosts(); // Updated API call
        setPosts(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Show only the 3 latest blog posts
  const latestPosts = posts.slice(0, 3);

  return (
    <section className='latest-blog'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='latest-blog-heading'>
              <h1 className='text-center heading'>Latest Blog</h1>
            </div>
            <div className='latest-blog-content'>
              <div className="row blog-posts">
                {latestPosts.map((post, index) => (
                  <div className='col-lg-4 mb-3' key={post._id || index}>
                    <div className="blog-card">
                      <div className='blog-featured-img'>
                        <img className="img-fluid" loading="lazy" alt={post.title} src={getImageUrl(post.image)} />
                      </div>
                      <div className="blog-content">
                        <div className="a-badge">
                          <div className="text">{post.categories[0]}</div>
                        </div>
                        <div className="title-parent">
                          <div className="title">{post.title}</div>
                          <div className="blog-footer">
                            <div className="a-author-parent">
                              <div className="a-author">
                                <div className="author">{post.author}</div>
                              </div>
                              <div className="date">{new Date(post.createdAt).toLocaleDateString()}</div>
                            </div>
                            <div className="read-more-button-parent">
                              <Link 
                                to={`/blog-details-page/${encodeURIComponent(post.title)}`}
                                className="btn read-more-button"
                              >Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='read-more'>
              <Link to={`/blog`} className="btn blue-btn">View More</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Latest_Blog;
