import React from "react";
import "../global.css";
import "../style/HomePage.css";
import Header from "../components/header";
import Testimonial from "../components/testimonial";
import Latest_Blog from "../components/latest-blog";
import Footer from "../components/footer";
import CTA from "../components/cta";
import SEOWrapper from '../components/SEOWrapper';
import { useNavigate } from 'react-router-dom';
import aeroImage from "../assets/group-17379@2x.png";
import heroBanner from "../assets/hero-banner-img.png";
import smile from "../assets/smile.png";
import phone from "../assets/phone-icon.png";
import enevelope from "../assets/envelope-icon.png";
import timer from "../assets/timer-icon.png";
import doctor1 from "../assets/doctors-group-1-img.png";
import doctor2 from "../assets/doctor-img.png";
import doctor3 from "../assets/doctors-group-2-img.png";
import reason from "../assets/more-reasons-img.png";
import moreReason from "../assets/more-reasons-img.png";
import underline from "../assets/underline-img.png";
import checkup from "../assets/accident-checkup-img.png";
import attorney from "../assets/rectangle-631@2x.png";
import underline1 from "../assets/underline-img-2.png";
import smileface from "../assets/smiling-face.png";


const HomePage = () => {
  
  const pageName = new URL(import.meta.url).pathname.split('/').pop().replace('.jsx', '');

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/contact-us');
  };
  return (
    <SEOWrapper pageName={pageName}>
      <div>
        <Header />
        <div className="home-page">
        <section className="hero-banner w-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="hero-banner-content">
                  <h1>
                    Car Accident Clinic <br/> That You Can Count On
                  </h1>
                  <h3 className="find-the-best">
                    Find the best Doctor for Car Accident Care and Treatment Center
                    in Beaumont and 100 more locations across Houston.
                  </h3>
                  <div className="call-to-action-wrapper">
                      <div className="call-to-action" onClick={handleRedirect}>
                        <div className="get-in-touch">Get in Touch</div>
                        <img
                          className="call-to-action-child"
                          alt=""
                          src={aeroImage}
                        />
                        </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero-banner-img">
                  <img className="img-fluid hero-img" alt="" src={heroBanner} />
                  <div className="happy-patients-box" data-animate-on-scroll>
                    <div className="happy-patients-box-icon">
                      <img className="img-fluid" loading="lazy" alt="" src={smile} />
                    </div>
                    <div className="k-parent">
                      <div className="k">84k+</div>
                      <div className="happy-patients-wrapper">
                        <div className="happy-patients">Happy Patients</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </section>
        <section className="hero-banner-contactinfo w-100">
          <div className="container">
            <div className="hero-banner-contactinfo-content">
              <div className="row">
                <div className="col-lg-4">
                  <div className="contactinfo-box">
                    <img className="img-fluid" loading="lazy" alt="" src={phone} />
                    <h3>4098344100</h3>
                    <p>Keep on touch</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contactinfo-box">
                    <img className="img-fluid" loading="lazy" alt="" src={enevelope}/>
                    <h3>admin@caraccidnetcares.com</h3>
                    <p>Keep on touch</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contactinfo-box">
                    <img className="img-fluid" loading="lazy" alt="" src={timer}/>
                    <h3>08am - 05pm</h3>
                    <p>Monday to Friday</p>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </section>
        <section className="injuries-specialists">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="injuries-specialists-heading">
                  <h1 className="text-center heading">Choose the #1 Auto Injuries Specialists Near You</h1>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="injuries-specialists-img">
                  <img className="img-fluid doctors-group-1-img" alt="" src={doctor1} />
                  <div className="doctor-img">
                    <div className="doctor-img-bg">
                      <div className="bg-img"></div>
                    </div>
                    <img className="img-fluid" alt="" src={doctor2} />
                  </div>
                  <img className="img-fluid doctors-group-2-img" alt="" src={doctor3}/>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="injuries-specialists-content">
                  <h4>Get Customized Car Accident Checklist with Attorney Referrals</h4>
                  <p>We recognize the value of high-quality medical care. That is why we established our organization 
                    to provide complete assistance in car accident care. We have a team of high-quality car accident 
                    chiropractors, primary care physicians, surgeons, accident care specialists, and pain management 
                    specialists. We cover more than 100 sites, allowing us to match our clients with the best 
                    chiropractic injury or medical practitioners in their area. Along with all the specialized 
                    treatments, you can also get attorney referrals and personal injury lawyers to get a smooth legal 
                    process post the accident.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fast-recovery">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="fast-recovery-heading">
                  <h1 className="text-center heading">We Assure Fast Recovery & Smooth Legalities</h1>
                </div>
              </div>
              <div className="col-12">
                <div className="fast-recovery-content">
                  <div className="fast-recovery-box right">
                    <h3>Car Accident Doctors</h3>
                    <p>We have a team of auto injuries specialists in Beaumont and anywhere in the Houston. Finding an affordable Chiropractor near you is only a few clicks 
                      away. A vehicle accident causes great financial as well as physical loss. Even a small physical injury can lead to serious consequences in the future. 
                      Hence, we believe in providing next-level car accident and injury services for you.</p>
                  </div>
                  <div className="fast-recovery-box left">
                    <h3>Attorney Referrals</h3>
                    <p>After a car accident, it is important to protect your rights. We provide skilled car injury attorney referrals in your location. So that you can focus 
                      on your health rather than worrying about finding a trusted attorney. You don't have to worry about losing the case, accepting a low settlement, or 
                      chaotic insurance claims. Our attorneys will take care of all the legal formalities and guide you along the way.</p>
                  </div>
                  <div className="fast-recovery-box right">
                    <h3>Attorney Partners</h3>
                    <p>If you are an attorney, we help your clients with prompt medical treatments. We want you to remember us every time your client faces a car accident. Our 
                      trusted team of doctors and medical experts is available in over 100+ locations in the Houston. We understand the importance of prompt medical care for 
                      those injured in a car accident. Get in touch with our attorney partners to seek immediate medical help anywhere in the country.</p>
                  </div>
                  <div className="fast-recovery-box left">
                    <h3>Personal Injury Lawyer</h3>
                    <p>Get a personal injury lawyer that will tell you exactly what to do after a car accident. Our dedicated team of lawyers helps you to focus on your 
                      well-being while they take care of legalities in your favor. Get the claims you deserve in the shortest amount of time with the most reliable attorneys 
                      in your locality. Whether you need a therapist or a chiropractor, we help you get in touch with the best medical and legal services in your area.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="more-reason">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="more-reason-heading">
                  <h1 className="text-center heading">More Reasons to Choose Our Accident Care & Treatment Center</h1>
                </div>
                <div className="more-reason-content">
                  <img className="img-fluid" loading="lazy" alt="" src={reason}/>
                  <ul>
                    <li>Covering 100+ Locations across the Houston</li>
                    <li>Comprehensive Post Accident Assistance</li>
                    <li>On-Demand Personal Injury Attorneys</li>
                    <li>Highly Skilled Attorney References</li>
                    <li>Providing a Complete Car Injury Checklist</li>
                    <li>Affordable Chiropractors & Medical Professionals</li>
                    <li>Fast and Simple Process</li>
                    <li>Transportation Facility</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Testimonial />
        <section className="specialist">
          <div className="container">
            <div className="row">
              <div className="specialist-heading">
                <h1 className="text-center heading">Find Auto Injury Specialist Near You</h1>
              </div>
              <div className="specialist-content">
                <p className="text-center">Car crash is a major accident which require immediate medical attention. Whether your accident was small or big, it is important to see a doctor within 72 
                  hours of the crash. We serve people who need help with medical treatments, attorneys, and insurance guidance anywhere in the Houston. apart from Beaumont, 
                  Texas, our car accident care is available in 100 more locations. Find the nearest car injury clinic from here.</p>
                <img className="img-fluid" loading="lazy" alt="" src={moreReason}/>
              </div>
              <div className="specialist-btn">
                <a href="/contact-us" className="btn blue-btn">Give Us a Call</a>
              </div>
            </div>
          </div>
        </section>
        <section className="accident-checkup">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="accident-checkup-content">
                  <h1 className="heading">Post Accident Checkup</h1>
                  <img className="ubderline-img" loading="lazy" alt="" src={underline}/>
                  <p>Car accident may cause minor or major damage to body parts including neck, back, hands, legs, and more. We provide immediate checkups and medical 
                    treatments after your car accident. Give us a call and find our clinic near your location.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="accident-checkup-img">
                  <img className="img-fluid" loading="lazy" alt="" src={checkup} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="attorney-assistance">
          <div className="container">
            <div className="attorney-assistance-box">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="attorney-assistance-img">
                    <img className="img-fluid" loading="lazy" alt="" src={attorney} />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="attorney-assistance-content">
                    <h1 className="heading">Attorney Assistance</h1>
                    <p>We simplify the legalities after a car crash. So you can focus on your health 
                      instead of dealing with paperwork and insurance offices. We help your attorneys 
                      with the post-crash formalities. Or we can also find the right attorney for you 
                      if you need one.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="attorney-reference">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="attorney-reference-content">
                  <h1 className="heading">Attorney Reference</h1>
                  <img className="underline-img" loading="lazy" alt="" src={underline1} />
                  <p>Car accident may cause minor or major damage to body parts including neck, back, hands, legs, and more. We provide immediate checkups and medical 
                    treatments after your car accident. Give us a call and find our clinic near your location.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="attorney-reference-img">
                  <img className="img-fluid" loading="lazy" alt="" src={smileface}/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Latest_Blog />
        <CTA />
        <Footer />
      </div>
      </div>
    </SEOWrapper>
  );
};

export default HomePage;