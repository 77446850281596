import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchPublicDynamicPages } from '../utils/api'; // Adjust the import path as needed
import "./MegaMenu.css";
import "../global.css";

const MegaMenu = ({ menuType }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadMenuItems = async () => {
      try {
        const data = await fetchPublicDynamicPages();
        setItems(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to load menu items');
        setLoading(false);
      }
    };

    loadMenuItems();
  }, []);

  if (loading) return <div className="mega-menu-loading">Loading...</div>;
  if (error) return <div className="mega-menu-error">{error}</div>;
  if (!items.length) return <div className="mega-menu-error">No pages available</div>;

  return (
    <ul className="dropdown-menu show">
      {items.map((page) => (
        <li key={page._id || page.title}> {/* Ensure unique key prop */}
          <Link className="dropdown-item" to={`/${menuType === 'Service' ? 'service' : 'injuries'}-detail/${page._id}`}>
            {page.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default MegaMenu;
