import axios from 'axios';
import { API_BASE_URL } from '../config';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Add this interceptor to log all requests
api.interceptors.request.use(request => {
  console.log('Starting Request', request)
  return request
})

const handleApiError = (error, message) => {
  console.error(message, error.response?.data || error.message);
  throw new Error(message);
};

// Fetch all posts
// export const fetchPosts = async () => {
//   try {
//     const response = await api.get('/posts');
//     return response.data;
//   } catch (error) {
//     handleApiError(error, 'Error fetching posts');
//     throw error;
//   }
// };

export const fetchAllPosts = async () => {
  try {
    const response = await api.get('/posts/all');
    return response.data;
  } catch (error) {
    console.error('Error fetching all posts:', error);
    throw error;
  }
};

export const fetchPostByTitle = async (title) => {
  try {
    const response = await api.get(`/posts/title/${encodeURIComponent(title)}`); // Properly encode the title
    return response.data;
  } catch (error) {
    console.error('Error fetching post by title:', error.response ? error.response.data.message : error.message);
    throw new Error(error.message);
  }
};


// // Fetch all testimonials
// export const fetchTestimonials = async () => {
//   try {
//     const response = await api.get('/testimonials');
//     return response.data;
//   } catch (error) {
//     handleApiError(error, 'Error fetching testimonials');
//   }
// };

// Fetch all public testimonials
export const getAllPublicTestimonials = async () => {
  try {
    const response = await api.get('/testimonials');
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error fetching public testimonials');
    throw error; // Rethrow error if you want to handle it further up the call chain
  }
};

// Create a new appointment
// export const createAppointment = async (appointmentData) => {
//   try {
//     const response = await api.post('/appointments', appointmentData);
//     return response.data;
//   } catch (error) {
//     handleApiError(error, 'Error creating appointment');
//   }
// };

// Create a new public appointment
export const createPublicAppointment = async (appointmentData) => {
  try {
    const response = await api.post('/public/appointments', appointmentData); // Adjust endpoint
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error creating public appointment');
    throw error; // Rethrow error if you want to handle it further up the call chain
  }
};

// Fetch SEO data by page name
// export const fetchSeoData = async (pageName) => {
//   try {
//     const response = await api.get(`/seo/seo-data/${pageName}`);
//     return response.data;
//   } catch (error) {
//     handleApiError(error, 'Error fetching SEO data');
//   }
// };


// Fetch SEO data from the public endpoint
export const fetchPublicSeoData = async (pageName) => {
  try {
    const response = await api.get(`/seo/seo-data/${pageName}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching SEO data:', error);
    throw error;
  }
};

// export const fetchProviders = async () => {
//   try {
//     const response = await api.get('/providers');
//     return response.data;
//   } catch (error) {
//     handleApiError(error, 'Error fetching providers');
//   }
// };

export const fetchProviders = async () => {
  try {
      const response = await api.get('/providers');
      return response.data;
  } catch (error) {
      console.error("Error fetching providers:", error);
      throw error;
  }
};

// export const fetchDynamicPages = async () => {
//   try {
//     const response = await api.get('/dynamic-pages');
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching dynamic pages:', error);
//     throw new Error('Error fetching dynamic pages');
//   }
// };

export const fetchPublicDynamicPages = async () => {
  try {
    const response = await api.get('/dynamic-pages'); // Adjust the endpoint based on your routes
    return response.data;
  } catch (error) {
    console.error('Error fetching public dynamic pages:', error);
    throw error;
  }
};

export const fetchDynamicPage = async (id) => {
  try {
    const response = await api.get(`/dynamic-pages/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching dynamic page:', error);
    throw new Error('Error fetching dynamic page');
  }
};

export default api;
