// src/utils/imageUtils.js

import { API_IMAGE_BASE_URL } from '../config';

export const getImageUrl = (filename) => {
  if (!filename) return '';

  const url = `${API_IMAGE_BASE_URL}/uploads/${filename}`;
  console.log("Generated Image URL:", url); // Debug log
  return url;
};
