import React from 'react';
import "./footer.css";
import "../global.css";
import { Link } from 'react-router-dom';
import footerLogo from '../assets/caraccidentcares-logo.png'; // Import the footer logo
import appointmentIcon from '../assets/appointment-icon.png'; // Import the appointment icon
import whatsappIcon from '../assets/whatsapp-icon.png'; // Import the WhatsApp icon

const Footer = () => {
  return (
    <>
      <section className='footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='footer-widget'>
                <img className="img-fluid footer-logo" loading="lazy" alt="Car Accident Cares Logo" src={footerLogo} /> {/* Use the imported footer logo */}
                <div className='take-an-appointment-btn'>
                  <img className="appointment-icon" alt="Appointment Icon" src={appointmentIcon} /> {/* Use the imported appointment icon */}
                  <Link to="#">Take an Appointment</Link>
                </div>
                <div className='connect-on-whatsapp-btn'>
                  <img className="whatsapp-icon" alt="WhatsApp Icon" src={whatsappIcon} /> {/* Use the imported WhatsApp icon */}
                  <Link to="#">Connect on Whatsapp</Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='footer-widget'>
                <h4>Our Services</h4>
                <ul>
                  <li><Link to="#">Chiropractic Care in Beaumont</Link></li>
                  <li><Link to="#">MD Consultation in Beaumont</Link></li>
                  <li><Link to="#">Pain Management Consultation in Beaumont</Link></li>
                  <li><Link to="#">Orthopedic and Spine Surgeon Consultation Beaumont</Link></li>
                  <li><Link to="#">Emergency Room in Beaumont</Link></li>
                </ul>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='footer-widget'>
                <h4>Types Of Injuries</h4>
                <ul>
                  <li><Link to="#">Our Services</Link></li>
                  <li><Link to="#">Chiropractic Care in Beaumont</Link></li>
                  <li><Link to="#">MD Consultation in Beaumont</Link></li>
                  <li><Link to="#">Pain Management Consultation in Beaumont</Link></li>
                  <li><Link to="#">Orthopedic and Spine Surgeon Consultation Beaumont</Link></li>
                  <li><Link to="#">Emergency Room in Beaumont</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='bottom-footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <p>&copy; Front. 2022 Car Accident Care. All rights reserved.</p>
            </div>
            <div className='col-lg-6'>
              <p className='text-end'>
                Design & Develop with <span style={{ color: 'red', margin: '0 5px' }}>❤️</span> by&nbsp;
                <a href="https://illusiodesigns.agency/" target="_blank" rel="noopener noreferrer">
                  Illusio Designs
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

export default Footer;
