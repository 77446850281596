import React, { useEffect, useState } from 'react';
import "../global.css";
import "../style/services.css";
import Header from "../components/header";
import Testimonial from "../components/testimonial";
import Latest_Blog from "../components/latest-blog";
import Footer from "../components/footer";
import CTA from "../components/cta";
import SEOWrapper from '../components/SEOWrapper';
import api from '../utils/api';
import { Link } from 'react-router-dom';

// Image imports
import phoneIcon from '../assets/phone-icon.png';
import envelopeIcon from '../assets/envelope-icon.png';
import timerIcon from '../assets/timer-icon.png';
import serviceImg from '../assets/service-img.png';
import insuranceCoverageImg from '../assets/insurance-coverage-img.png';
import autoInjuriesImg from '../assets/specialized-in-auto-injuries-img.png';
import attorneyReferralsImg from '../assets/medical-care-with-attorney-referrals-img.png';

const Services = () => {
  const pageName = new URL(import.meta.url).pathname.split('/').pop().replace('.jsx', '');
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await api.get('/dynamic-pages'); // Adjust the endpoint as needed
        const servicePages = response.data.filter(page => page.pageType === 'Service');
        setServices(servicePages);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  return (
    <>
      <SEOWrapper pageName={pageName} />
      <Header />
      <section className="service-breadcrumb breadcrumb-img">
        <h1 className='text-center heading'>{pageName}</h1>
      </section>
      <section className="hero-banner-contactinfo w-100">
        <div className="container">
          <div className="hero-banner-contactinfo-content">
            <div className="row">
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img className="img-fluid" loading="lazy" alt="Phone Icon" src={phoneIcon} />
                  <h3>4098344100</h3>
                  <p>Keep in touch</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img className="img-fluid" loading="lazy" alt="Envelope Icon" src={envelopeIcon} />
                  <h3>admin@caraccidentcares.com</h3>
                  <p>Keep in touch</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img className="img-fluid" loading="lazy" alt="Timer Icon" src={timerIcon} />
                  <h3>08am - 05pm</h3>
                  <p>Monday to Friday</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='service-list'>
        <div className='container'>
          <div className='row'>
            {services.map((service) => (
              <div key={service._id} className='col-lg-4 col-md-6 mb-4'>
                <div className='service-list-box'>
                  <h3><Link to={`/service-detail/${service.id}`}>{service.title}</Link></h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <section className="services">
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='service-content'>
                <div className='service-img'>
                  <img className="footer-container-item img-fluid" alt="Service" src={serviceImg} />
                </div>
                <div className='service'>
                  <div className='d-flex'>
                    <div className='service-box service-box-1'>
                      <img className="footer-container-item img-fluid" alt="Insurance Coverage" src={insuranceCoverageImg} />
                      <h3>Insurance Coverage</h3>
                    </div>
                    <div className='service-box service-box-2'>
                      <img className="footer-container-item img-fluid" alt="Auto Injuries" src={autoInjuriesImg} />
                      <h3>Specialized in Auto Injuries</h3>
                    </div>
                    <div className='service-box service-box-3'>
                      <img className="footer-container-item img-fluid" alt="Attorney Referrals" src={attorneyReferralsImg} />
                      <h3>Medical Care with Attorney Referrals</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='services-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='service-box'>
                <img className="footer-container-item img-fluid" alt="Insurance Coverage" src={insuranceCoverageImg} />
                <h3>Insurance Coverage</h3>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='service-box'>
                <img className="footer-container-item img-fluid" alt="Auto Injuries" src={autoInjuriesImg} />
                <h3>Specialized in Auto Injuries</h3>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='service-box'>
                <img className="footer-container-item img-fluid" alt="Attorney Referrals" src={attorneyReferralsImg} />
                <h3>Medical Care with Attorney Referrals</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
      <Latest_Blog />
      <CTA />
      <Footer />
    </>
  );
};

export default Services;
