import { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigationType } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Services from "./pages/services";
import ContactUs from "./pages/contact-us";
import Blog from "./pages/blog";
import Injuries from "./pages/Injuries";
import BlogDetailsPage from "./pages/blog-details-page";
import Provider from "./pages/provider";
import ServicesDetail from "./components/services-detail";
import api from "./utils/api";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  const [dynamicPages, setDynamicPages] = useState([]);

  useEffect(() => {
    const fetchDynamicPages = async () => {
      try {
        const response = await api.get("/dynamic-pages");
        setDynamicPages(response.data);
      } catch (err) {
        console.error("Error fetching dynamic pages:", err);
      }
    };

    fetchDynamicPages();
  }, []);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home";
        metaDescription = "Welcome to our homepage.";
        break;
      case "/services":
        title = "Our Services";
        metaDescription = "Discover our range of services.";
        break;
      case "/contact-us":
        title = "Contact Us";
        metaDescription = "Get in touch with us.";
        break;
      case "/blog":
        title = "Blog";
        metaDescription = "Read our latest articles.";
        break;
      case "/injuries":
        title = "Type of Injuries";
        metaDescription = "Find out about different types of injuries.";
        break;
      case pathname.match(/^\/blog-details-page\/[a-zA-Z0-9]+$/)?.input:
        title = "Blog Details";
        metaDescription = "Read our latest articles.";
        break;
      case pathname.match(/^\/services-detail\/[a-zA-Z0-9]+$/)?.input:
      case pathname.match(/^\/injuries-detail\/[a-zA-Z0-9]+$/)?.input:
        title = "Service Details";
        metaDescription = "Learn more about this specific service.";
        break;
      default:
        const dynamicPage = dynamicPages.find((page) => `/${page.slug}` === pathname);
        if (dynamicPage) {
          title = dynamicPage.title;
          metaDescription = dynamicPage.description;
        } else {
          title = "Page Not Found";
          metaDescription = "This page does not exist.";
        }
    }

    document.title = title || "Default Title";

    let metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (!metaDescriptionTag) {
      metaDescriptionTag = document.createElement("meta");
      metaDescriptionTag.name = "description";
      document.head.appendChild(metaDescriptionTag);
    }
    metaDescriptionTag.content = metaDescription || "Default Description";
  }, [pathname, dynamicPages]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/injuries" element={<Injuries />} />
      <Route path="/blog-details-page/:title" element={<BlogDetailsPage />} />
      <Route path="/provider" element={<Provider />} />
      <Route path="/service-detail/:id" element={<ServicesDetail />} />
      <Route path="/injuries-detail/:id" element={<ServicesDetail />} />
      {/* {dynamicPages.map((page) => (
        <Route key={`dynamic-${page._id}`} path={`/${page.slug}`} element={<ServicesDetail />} />
      ))} */}
    </Routes>
  );
}

export default App;
