import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDynamicPage } from '../utils/api'; // Adjust the import path as needed
import "../global.css";
import "./services-detail.css";
import Latest_Blog from './latest-blog';
import Header from './header';
import Testimonial from './testimonial';
import Footer from './footer';
import CTA from './cta';
import underline from "../assets/underline-img.png";

const Services_Detail = () => {
  const { id } = useParams(); // Get id from URL parameters
  const [dynamicPage, setDynamicPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDynamicPageData = async () => {
      try {
        const response = await fetchDynamicPage(id);
        setDynamicPage(response);
        setLoading(false);
      } catch (err) {
        setError('Failed to load content. Please try again later.');
        setLoading(false);
      }
    };

    if (id) {
      fetchDynamicPageData();
    }
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!dynamicPage) return <div>Page not found.</div>; // Handle case where page not found

  return (
    <>
      <Header />
      <section className="contact-breadcrumb breadcrumb-img">
        <h1 className="text-center heading">Services</h1>
      </section>
      <section className="hero-banner-contactinfo w-100">
        <div className="container">
          <div className="hero-banner-contactinfo-content">
            <div className="row">
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img
                    className="img-fluid"
                    loading="lazy"
                    alt="Phone Icon"
                    src="/public/phone-icon.png"
                  />
                  <h3>4098344100</h3>
                  <p>Keep in touch</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img
                    className="img-fluid"
                    loading="lazy"
                    alt="Envelope Icon"
                    src="/public/envelope-icon.png"
                  />
                  <h3>admin@caraccidentcares.com</h3>
                  <p>Keep in touch</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img
                    className="img-fluid"
                    loading="lazy"
                    alt="Timer Icon"
                    src="/public/timer-icon.png"
                  />
                  <h3>08am - 05pm</h3>
                  <p>Monday to Friday</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='service-detail'>
        <section className="service-section-1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="service-section-1-content">
                  <h1 className="heading">{dynamicPage.title}</h1>
                  <img className="ubderline-img" loading="lazy" alt="" src={underline} />
                  <p>{dynamicPage.sections[0]?.content}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-section-1-img">
                  <img className="img-fluid" loading="lazy" alt="" src={dynamicPage.sections[0]?.image} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {dynamicPage.sections[1] && (
          <section className="service-section-2">
            <div className="container">
              <div className="service-section-2-box">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="service-section-2-img">
                      <img className="img-fluid" loading="lazy" alt="" src={dynamicPage.sections[1].image} />
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="service-section-2-content">
                      <h1 className="heading">{dynamicPage.sections[1].heading}</h1>
                      <p>{dynamicPage.sections[1].content}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </section>
      <Testimonial />
      <Latest_Blog />
      <CTA />
      <Footer />
    </>
  );
};

export default Services_Detail;
