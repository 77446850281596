import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../style/blog-details-page.css';
import '../global.css';
import Header from "../components/header";
import { fetchPostByTitle } from '../utils/api'; 
import { getImageUrl } from '../utils/imageUtils';
import Testimonial from '../components/testimonial';
import Latest_Blog from '../components/latest-blog';
import CTA from '../components/cta';
import Footer from '../components/footer';
import SEOWrapper from '../components/SEOWrapper';

import phone from "../assets/phone-icon.png";
import enevelop from "../assets/envelope-icon.png";
import timer from "../assets/timer-icon.png";

const BlogDetailsPage = () => {
  const location = useLocation();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const pageName = 'BlogDetailsPage'; 

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const title = decodeURIComponent(pathParts[pathParts.length - 1]);
  
    console.log('Extracted Title:', title); // Add this to see what title is being extracted
  
    if (!title) {
      setError('No post title provided');
      setLoading(false);
      return;
    }
  
    const fetchPost = async () => {
      try {
        console.log('Fetching post with title:', title); // Debugging log
        const data = await fetchPostByTitle(title);
        console.log('Post data:', data); // Debugging log
        setPost(data);
      } catch (error) {
        setError('Failed to fetch post: ' + error.message);
        console.error('Failed to fetch post:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPost();
  }, [location.pathname]);
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!post) return <div>Post not found.</div>;

  // Convert categories and tags to arrays if they are strings
  const categories = Array.isArray(post.categories) ? post.categories : post.categories.split(',');
  const tags = Array.isArray(post.tags) ? post.tags : post.tags.split(',');

  return (
    <>
      <SEOWrapper pageName={pageName} />
      <Header />
      <section className='blog-detail-breadcrumb breadcrumb-img'>
        <h1 className='text-center heading'>Blog</h1>
      </section>
      <section className="hero-banner-contactinfo w-100">
        <div className="container">
          <div className="hero-banner-contactinfo-content">
            <div className="row">
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img className="img-fluid" loading="lazy" alt="Phone Icon" src={phone} />
                  <h3>4098344100</h3>
                  <p>Keep in touch</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img className="img-fluid" loading="lazy" alt="Envelope Icon" src={enevelop} />
                  <h3>admin@caraccidnetcares.com</h3>
                  <p>Keep in touch</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img className="img-fluid" loading="lazy" alt="Timer Icon" src={timer} />
                  <h3>08am - 05pm</h3>
                  <p>Monday to Friday</p>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </section>
      <div className="blog-details-page">
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <section className="blog-detail-content">
                <div className="blog-info">
                  <h1>{post.title}</h1>
                  <div className='author-info'>
                    <div className='author'>
                      <span className='author-name'>{post.author}</span>
                    </div>
                    <div className='date'>
                      {new Date(post.createdAt).toLocaleDateString()}
                    </div>
                  </div>
                  <div className="categories">
                    <strong>Categories:</strong> <span className='a-badge'>{categories.join(', ')}</span>
                  </div>
                  <div className="tags">
                    <strong>Tags:</strong> <span className='a-badge'>{tags.join(', ')}</span>
                  </div>
                </div>
                <div className='blog-content'>
                  <div className='featured-img'>
                    <img className="img-fluid" loading="lazy" alt="Blog" src={getImageUrl(post.image)} />
                  </div>
                  <p>{post.content}</p>
                </div>
              </section>
            </div>
          </div>
        </div>      
      </div>
      <Testimonial />
      <Latest_Blog />
      <CTA />
      <Footer />
    </>
  );
};

export default BlogDetailsPage;
