import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { fetchPublicSeoData } from '../utils/api'; // Adjust the import path as needed

const SEOWrapper = ({ pageName, children }) => {
  const [seoData, setSeoData] = useState({
    seoTitle: `Default Title for ${pageName}`,
    seoDescription: `Default description for ${pageName}`,
    canonicalUrl: window.location.href,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSeoData = async () => {
      try {
        const data = await fetchPublicSeoData(pageName);
        if (data) {
          setSeoData({
            seoTitle: data.seoTitle || `Default Title for ${pageName}`,
            seoDescription: data.seoDescription || `Default description for ${pageName}`,
            canonicalUrl: data.canonicalUrl || window.location.href,
          });
        }
      } catch (error) {
        console.error('Error fetching SEO data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getSeoData();
  }, [pageName]);

  if (loading) {
    return <div>Loading SEO data...</div>; // More descriptive loading indicator
  }

  if (error) {
    console.warn('Using default SEO data due to error:', error);
  }

  return (
    <>
      <Helmet>
        <title>{seoData.seoTitle}</title>
        <meta name="description" content={seoData.seoDescription} />
        <link rel="canonical" href={seoData.canonicalUrl} />
      </Helmet>
      {children}
    </>
  );
};

export default SEOWrapper;
