import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MegaMenu from './MegaMenu';
import './header.css';
import '../global.css';
import api from '../utils/api';
import carAccidentCaresLogo from '../assets/car-accident-cares-logo.png'; // Import the image

const Header = () => {
  const [activeMegaMenu, setActiveMegaMenu] = useState(null);
  const [services, setServices] = useState([]);
  const [injuries, setInjuries] = useState([]);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        console.log("Fetching all dynamic pages...");
        const response = await api.get('/dynamic-pages');
        const allPages = response.data;
        console.log("All pages fetched from server:", allPages);

        const servicePages = allPages.filter(page => page.pageType === 'Service');
        const injuryPages = allPages.filter(page => page.pageType === 'Injuries');

        console.log("Filtered service pages:", servicePages);
        console.log("Filtered injury pages:", injuryPages);

        setServices(servicePages);
        setInjuries(injuryPages);
      } catch (error) {
        console.error('Error fetching dynamic pages:', error);
      }
    };

    fetchPages();
  }, []);

  const handleMouseEnter = (menuName) => {
    setActiveMegaMenu(menuName);
  };

  const handleMouseLeave = () => {
    setActiveMegaMenu(null);
  };

  const handleClick = (link) => {
    // Navigate to the clicked page using react-router's Link
    window.location.href = link;
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link to="/" className='navbar-brand'>
          <img className="img-fluid" loading="lazy" alt="Car Accident Cares Logo" src={carAccidentCaresLogo} /> {/* Use the imported image */}
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" to="/">Home</Link>
            </li>
            <li 
              className="nav-item dropdown" 
              onMouseEnter={() => handleMouseEnter('Service')} 
              onMouseLeave={handleMouseLeave}
            >
              <Link 
                className="nav-link dropdown-toggle"
                to="/services"
                aria-expanded={activeMegaMenu === 'Service'}
              >
                Services
              </Link>
              {activeMegaMenu === 'Service' && <MegaMenu items={services} menuType="Service" />}
            </li>
            <li 
              className="nav-item dropdown" 
              onMouseEnter={() => handleMouseEnter('Injuries')} 
              onMouseLeave={handleMouseLeave}
            >
              <Link 
                className="nav-link dropdown-toggle"
                to="/injuries"
                aria-expanded={activeMegaMenu === 'Injuries'}
              >
                Type of Injuries
              </Link>
              {activeMegaMenu === 'Injuries' && <MegaMenu items={injuries} menuType="Injuries" />}
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/provider">Our Providers</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog">Blog</Link>
            </li>
          </ul>
          <Link to="/contact-us" className='navbar-btn'>Get In Touch</Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
