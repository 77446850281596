import React, { useEffect, useState } from 'react';
import { getAllPublicTestimonials } from '../utils/api'; // Adjust the import path as needed

import "./testimonial.css";
import "../global.css";
import underline from "../assets/underline-img-4.png";

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => (
        <span key={index} className={index < rating ? "star filled" : "star"}>
          ★
        </span>
      ))}
    </div>
  );
};

const Testimonial = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadTestimonials = async () => {
            try {
                const data = await getAllPublicTestimonials(); // Use the API function here
                setTestimonials(data);
            } catch (err) {
                setError('Failed to fetch testimonials');
                console.error('Error fetching testimonials:', err);
            } finally {
                setLoading(false);
            }
        };

        loadTestimonials();
    }, []);

    if (loading) return <div>Loading testimonials...</div>;
    if (error) return <div>{error}</div>;

    return (
      <section className='testimonial'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='testimonial-heading'>
                <h1 className='text-center heading'>Our Happy Clients</h1>
                <img className="img-fluid" loading="lazy" alt="" src={underline} />
              </div>
              <div className='testimonial-content'>
                <div className="testimonial-scroll-container">
                  <div className="testimonial-scroll-content">
                    {testimonials.concat(testimonials).map((testimonial, index) => (
                      <div key={index} className="testimonial-item">
                        <div className="testimonial-client-info">
                          <div className="client-name-container">
                            <div className="frame-parent10">
                              <div className="tyler-montez-wrapper">
                                <div className="tyler-montez">
                                  <p className="car-accident-clinic">{testimonial.name}</p>
                                </div>
                              </div>
                              <StarRating rating={testimonial.rating} />
                            </div>
                          </div>
                        </div>
                        <div className="after-my-fatal-car-container">
                          <p className="mb-0">
                            {testimonial.message}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Testimonial;
