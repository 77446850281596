import React, { useState } from "react";
import "./cta.css";
import "../global.css";
import { createPublicAppointment } from "../utils/api"; // Update the import for public appointment
import underlineImg from '../assets/underline-img-3.png'; // Import underline image
import mapImg from '../assets/map.jpg'; // Import map image

const CTA = () => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        message: "",
    });

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(false);

        try {
            await createPublicAppointment(formData); // Use the public appointment API function
            setSuccess(true);
            setFormData({ name: "", phone: "", email: "", message: "" });
        } catch (err) {
            setError("Failed to create appointment. Please try again.");
            console.error("Error creating appointment:", err);
        }
    };

    return (
        <div className="cta">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="faq">
                            <h1 className="text-center heading">Frequently Asked Questions</h1>
                            <img className="img-fluid" loading="lazy" alt="Underline" src={underlineImg} /> {/* Use the imported underline image */}
                            <div className="accordion faq-accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            When should I see a doctor after a car accident?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            We recommend you visit a doctor within 72 hours of your car accident. You should visit a doctor even if you have minor to no injuries. Get in touch 
                                            with a car accident clinic as soon as you can to avoid serious consequences in the future.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Accordion Item #2
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                                {/* Add other accordion items similarly */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="cta-contact-form">
                            <h1 className="text-center heading">Contact Us</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="name" 
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Name"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input 
                                        type="tel" 
                                        className="form-control" 
                                        id="phone" 
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder="Phone"
                                    />
                                </div>
                                <div className="mb-3">
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        id="email" 
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                    />
                                </div>
                                <div className="mb-3">
                                    <textarea 
                                        className="form-control" 
                                        id="message" 
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        placeholder="Message" 
                                        rows="4"
                                    ></textarea>
                                </div>
                                {error && <p className="text-danger">{error}</p>}
                                {success && <p className="text-success">Appointment created successfully!</p>}
                                <button type="submit" className="blue-btn">Submit Now</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="map-img">
                            <img className="img-fluid" alt="Map" src={mapImg} /> {/* Use the imported map image */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CTA;
