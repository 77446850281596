import React, { useState } from "react";
import "../global.css";
import "../style/contact-us.css";
import Header from '../components/header';
import Latest_Blog from '../components/latest-blog';
import Testimonial from '../components/testimonial';
import CTA from '../components/cta';
import Footer from '../components/footer';
import SEOWrapper from '../components/SEOWrapper';
import { createPublicAppointment } from "../utils/api"; // Update the import for public appointment

import phone from "../assets/phone-icon.png";
import envelope from "../assets/envelope-icon.png";
import timer from "../assets/timer-icon.png";

const Contact_us = () => {
  const pageName = new URL(import.meta.url).pathname.split('/').pop().replace('.jsx', '');

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    try {
      await createPublicAppointment(formData); // Use the public appointment API function
      setSuccess(true);
      setFormData({ name: "", phone: "", email: "", message: "" });
    } catch (err) {
      setError("Failed to create appointment. Please try again.");
      console.error("Error creating appointment:", err);
    }
  };

  return (
    <>
      <SEOWrapper pageName={pageName} />
      <Header />
      <section className="contact-breadcrumb breadcrumb-img">
        <h1 className="text-center heading">{pageName}</h1>
      </section>
      <section className="hero-banner-contactinfo w-100">
        <div className="container">
          <div className="hero-banner-contactinfo-content">
            <div className="row">
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img
                    className="img-fluid"
                    loading="lazy"
                    alt="Phone Icon"
                    src={phone}
                  />
                  <h3>4098344100</h3>
                  <p>Keep in touch</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img
                    className="img-fluid"
                    loading="lazy"
                    alt="Envelope Icon"
                    src={envelope}
                  />
                  <h3>admin@caraccidentcares.com</h3>
                  <p>Keep in touch</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contactinfo-box">
                  <img
                    className="img-fluid"
                    loading="lazy"
                    alt="Timer Icon"
                    src={timer}
                  />
                  <h3>08am - 05pm</h3>
                  <p>Monday to Friday</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-get-in-touch">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contact-get-in-touch-content">
                <b>Get in touch</b>
                <h1 className="contact-us2">Contact Us</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="phone" className="form-label">
                        Phone number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Enter your phone number"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        placeholder="Message"
                        rows="5"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <div className="col-lg-12">
                      <button type="submit" className="button">
                        Submit
                      </button>
                      {success && (
                        <div className="alert alert-success mt-3" role="alert">
                          Appointment created successfully!
                        </div>
                      )}
                      {error && (
                        <div className="alert alert-danger mt-3" role="alert">
                          {error}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="call-us">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="call-us-heading">
                <h1 className="text-center">
                  Had an Accident in Beaumont? Call us!
                </h1>
                <p className="text-center">
                  Car Accident Cares provides complete Accident Care services in
                  Beaumont and the surrounding areas. If you have been involved
                  in an accident, whether it is your fault or not, call us first
                  and we will come to your aid and walk you through every step
                  of the procedure.
                </p>
                <b className="text-center">
                  Why Should You Call Car Accident Cares?
                </b>
                <ul>
                  <li>Specialized Car Accident Care</li>
                  <li>24/7 Emergency Room</li>
                  <li>Insurance Coverage</li>
                  <li>Best Attorney Referrals</li>
                  <li>Experienced Medical Staff</li>
                </ul>
                <p className="text-center mt-4">
                  Car Accident Cares is Beaumont’s leader in skilled,
                  comprehensive, and effective medical care for auto-accident
                  injuries. Our doctor only treats people who have been in car
                  accidents. We’ve developed the most comprehensive medical
                  injury treatment program in the area, as well as a wealth of
                  knowledge and expertise in treating all forms of automobile
                  accident-related injuries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
      <Latest_Blog />
      <CTA />
      <Footer />
    </>
  );
};

export default Contact_us;
